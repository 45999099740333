import { NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ProfileComponent } from './pages/profile/profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import { Stripe } from '@ionic-native/stripe/ngx';
import { PdfService } from './shared/services/api/pdf.service';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {MatExpansionModule} from '@angular/material/expansion';
import { LeanrestoGalleryComponent } from './components/leanresto-gallery/leanresto-gallery.component';
import {MatMenuModule} from '@angular/material/menu';
import { ShowNotificationsComponent } from './components/notifications/show-notifications/show-notifications.component';
import { NotificationsItemsListComponent } from './components/notifications/notifications-items-list/notifications-items-list.component';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { NgxQRCodeModule } from 'ngx-qrcode2';
import { CommanForAllComponent } from './components/comman-for-all/comman-for-all.component';
import { AddNewTagComponent } from './components/add-new-tag/add-new-tag.component';
import { GoogleAuthRedirectComponent } from './pages/auth/google-auth-redirect/google-auth-redirect.component';
import { MicrosoftAuthRedirectComponent } from './pages/auth/microsoft-auth-redirect/microsoft-auth-redirect.component';
import { ConformationComponent } from './shared/utils/conformation/conformation.component';
import { IonicSelectableModule } from 'ionic-selectable';


@NgModule({
  declarations: [
    AppComponent,
    ProfileComponent,
    ShowNotificationsComponent,
    LeanrestoGalleryComponent,
    NotificationsItemsListComponent,
    CommanForAllComponent,
    AddNewTagComponent,
    GoogleAuthRedirectComponent,
    MicrosoftAuthRedirectComponent,
    ConformationComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    IonicModule.forRoot(), 
    IonicSelectableModule,
    AppRoutingModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    MatIconModule,
    MatExpansionModule,
    MatMenuModule,
    NgxQRCodeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    File,
    StatusBar,
    SplashScreen,
    Stripe,
    Geolocation,
    NativeGeocoder,
    FileTransfer,
    FileOpener,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    `/assets/i18n/`,
    '.json'
  );
}
